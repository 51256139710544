/* For Dowmloading Report */
@page {
  size: 210mm 297mm;
}

@media screen {
  div.printhead_comp {
    display: none;

  }

  div.printfoot_comp {
    display: none;
  }
}

@media print {
  .col-sm-12 {
    width: 32%;
    padding: 0;
  }

  .parameters_graphs {
    margin-bottom: 2px;
  }

  .graphs {
    padding: 0;
  }

  .pagebreak {
    page-break-before: always;
  }

  div.printhead_comp {
    top: 0;
    width: 100%;
  }

  div.printfoot_comp {
    bottom: 0;
    width: 100%;
    position: fixed;
  }

  div.nonprint_comp {
    display: none;
  }

  .soil__text_COPY {
    font-size: 8px;
    line-height: 10%;
  }

  .copyright_text {
    font-size: 8px;
  }

  .water_table td {
    padding: 5px;
  }

  .remark {
    bottom: 0;
  }

}