.bg-darkgreen {
    background-color: #113e21;
}

.text-darkgreen {
    color: #113e21 !important;
}

.homepage .card {
    padding: 25px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 8px;
    border-radius: 12px;
    justify-content: center;
    min-height: 200px;

}


/* Stats Titles */

.homepage .card .stats-table {
    /* background-color: red; */
    width: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: left !important;
}

.homepage .card .stats-table .number {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.2;
    padding-right: 20px;
    width: 20px;
    margin-right: 8px;
}

/* Booking Form  */
.homepage .booking-form .card-wrapper {
    display: flex;
    align-items: stretch;
    height: 100%;
}

.homepage .booking-form .card-wrapper .card {
    flex: 1;
}


/* .home_center_div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100% - 80.35px);

}

.home_center_div .top_div {
    height: 300px;
    border-radius: 25px;
    background-color: white;
}

.top_div img {
    max-height: 100%;
    max-width: 100%;
    margin: 50px auto;
}

.samplereport {
    margin: 10px;
    border-color: #113e21;
    border-radius: 37px;
    font-size: 12px;
    font-weight: 500;
    color: #113e21;
}

.samplereport:hover {
    border-color: #113e21;
    background-color: #113e21;
    color: white;
}

@media screen and (min-width:1000px) {
    .top_div img {
        max-height: 70%;
        max-width: 70%;
        margin: 50px auto;
    }
}

.top_div .inner_div {
    margin: auto;
    text-align: center;
}

table thead tr th {
    height: 50px !important;
}

tbody .table-view-btn {
    cursor: pointer;
} */


/* Mobile tabel view  */

@media (max-width:700px) {
    .table thead {
        display: none;
    }

    .table,
    .table tbody,
    .table tr,
    .table td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 15px;

    }

    .table tbody tr td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table tbody tr .no-entries{
        text-align: center !important;
        padding-left: 0 !important;
        position: relative;
    }

    .table td:before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: 600;
        font-size: 14px;
        text-align: left;
    }
}

