@media screen {
  .branding {
    display: none;
  }
}
@media print {
  /* First Section of report */
  .brand_info {
    background-color: #e1eae1;
    padding: 10px;
    margin: 10px auto;
    text-align: center;
  }
  .brand_section {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-weight: 400;
    color: #113e21;
    width: 100vw;
    height:705px;    
  }
  .brand_section p 
  {
    font-size: 12px;
    margin:0;
  }

  .brand__logo {
    width: 320px;
    height: 80px;
  }
  .company__logo {
    width: 340px;
    height: 110px;
    margin: 20px 0;
  }

  .note__section {
    margin-top: 130px !important;
  }

  ul.font_size {
    padding: 5px;
    margin-bottom: 0;
  }

  .note__section
  {
    padding: 20px !important;
  }
  .note__section .font_size {
    font-size: 8px !important;
    letter-spacing: 0.5;
    font-weight: 450;

  }
}
