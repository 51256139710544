.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Custom Styles */

input, select{
  border: 1px solid black !important;
  border-radius: 10px !important;
}

/* For handling farm size in booking page */
.input-group input{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input-group select{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
*:focus {
  box-shadow: none !important;
}
.form-floating > label::after {
  background-color: inherit !important;
}

.table_button {
  display: flex;
  justify-content: center;
  margin: 100px;
}

button {
  height: 55px;
  font-size: 16px !important;
  padding: 18px 22px;
  border-radius: 10px !important;
  color: #fff;
  background-color: #113e21;
  border: none;
  display: flex;
  align-items: center;
  font-weight: 500;
}

button:hover {
  opacity: 100% !important;
  color: black;
}

.header .navbar-toggler {
  background-color: #113e21 !important;
  /* color: black !important; */
}

.header .navbar-toggler:hover {
  opacity: 90% !important;
  /* color: black; */
}


.bg-lightgreen
{
  background-color: #DEF5E8 !important;
}

.bg-darkgreen
{
  background-color: #113e21 !important;
}


/* Cards */
.card {
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 8px;
  border-radius: 12px;
  justify-content: center;
  min-height: 200px;

}

/* Loading Icon */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-icon {
  font-size: 3rem;
  color: white;
}

.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
