/*---------------------------------- Bar Graph ------------------------------------- -*/
.bar_report {
  height: 100%;
  padding: 10px;
}

table {
  width: 100%;
  height: 100%;
}

.col_1 {
  width: 15%;

  align-items: center;
}

.col_2 {
  width: 60%;
}

.graph_lables {
  padding-left: 0;
  color: #113e21;
}

.graph_lables>li {
  /* margin: 10px 0; */
  font-weight: bold;
  font-size: 10px;
  text-align: right;
}

.graph_lables .range {
  /* font-size: 9px; */
  /* font-weight: normal; */
  margin-bottom: 0px !important;
  color: rgb(53, 52, 52);
}

.chart--horizon>li {
  list-style-type: none;
  height: 40px;
  margin-top: 30px;
}

.chart--horizon {
  width: 90%;
  padding-left: 10px;
}


.color_range {
  display: flex;
  justify-content: space-around;
}

table .label {
  font-weight: bold;
}

.bar_label {
  /* For both,jsx */
  margin: 10px;
  color: #113e21;
  /* margin-top: 20px; */

}

.graph {
  margin: 92px 0;
  height: 800px;
  width: 820px
}

.chart__bar {

  height: 30px;
  border-radius: 0 20px 20px 0;
  margin: 8px;
}

.chart__label {
  font-size: 16px;
  line-height: 40px;
  margin-left: 101.3%;
  color: #113e21;
  font-weight: 600;
}

.title {
  color: #113e21;
  font-weight: 700;
}

.second {
  margin: 60px 0;
}

.eFloatlow {
  background-color: #dc3912;
  height: 30px;
  border-radius: 0 20px 20px 0;
  margin: 8px 0;
}

.eFloatsufficient {
  background-color: #109618;
  height: 30px;
  border-radius: 0 20px 20px 0;
  margin: 8px 0;
}

.eFloathigh {
  background-color: #ff9900;
  height: 30px;
  border-radius: 0 20px 20px 0;
  margin: 8px 0;
}

/*---------------------------------- End Bar Graph -----------------------------------*/

/*---------------------------------- Gauge Graph ------------------------------------- -*/

/*---------------------------------- End Gauge Graph -----------------------------------*/

.report_sections {
  background-image: none;
  background-color: white;
  padding-bottom: 1%;
}

.print * {
  list-style: none;
}

/* Small (smaller than 640px) Medium (641px to 1007px) Large (1008px and larger) */
.label_font_size,
.label {
  font-size: 16px;
}

.font_size,
.content {
  font-size: 13px;
}

@media only screen and (max-width: 640px) {

  .label_font_size,
  .label {
    font-size: 11px;
  }

  .font_size,
  .content {
    font-size: 10px;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1007px) {

  .label_font_size,
  .label {
    font-size: 12px;
  }

  .font_size,
  .content {
    font-size: 13 px;
  }
}

/* Header */
.form__header {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  justify-content: flex-start;
}

.home__header--logo {
  height: 50px;
}

.home__header__name {
  color: #a46414;
  font-size: 25px;
  font-weight: 600;
}

.home__header__name>span {
  color: #113e21;
  font-size: 25px;
  font-weight: 500;
}

/* Radio Buttons */
.report_sections .radio {
  display: flex;
  justify-content: center;
  width: 100%;
}

.report_sections .radio span {
  font-size: 2vw;
  width: auto;
}

.report_sections .text__form {
  color: #113e21;
}

/* Report Content  */
.paper {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.report__header {
  background-color: #113e21;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.report__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__logo {
  height: 4vw;
  width: 4vw;
  padding: 4px;
  border-radius: 10px;
}

.header__name {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 600;
}

.header__heading {
  color: #a46414;
  float: inline-end;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 600;
}

@media only screen and (min-width: 1200px) {

  .header__name,
  .header__heading {
    font-size: 40px;
  }
}

/* First Section of report */
.info {
  background-color: #e1eae1;
  padding: 10px;
  margin-top: 10px;
}

.detail__section {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.detail__section--label {
  font-weight: 600;
  margin-right: 0.5rem;
}

/* Second Section of report */
.soil__info {
  background-color: #f2e7d9;
  color: #563104;
  margin-top: 10px;
  padding: 10px;
}

.soil__text {
  font-weight: 600;
  margin-right: 0.5rem;
}

/* Graphs Section */
.graphs {
  padding: 10px;
}

.parameters_graphs {
  padding: 10px;
  margin-bottom: 50px;
}

@media print {
  .parameters_graphs {
    margin-bottom: 0px;
  }

  .report {
    margin-top: 0px !important;
    width: 33% !important;
  }
}

.parameters_graphs:nth-child(2) {
  background-color: #f2e7d9;
}

.parameters_graphs .label,
.bar_report .label {
  color: #113e21;
  font-weight: 700;
}

.report {
  margin: 20px auto;
}

.speedometer {
  display: flex;
  margin: 0 auto;
}

.grid {
  display: grid;
  margin: 0px 40px;
  max-width: 45rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.data_rec {
  font-size: 12px;
  font-weight: 480;
  color: #113e21;
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
}

/* Remarks Section */
.remark {
  margin-top: 10px;
  background-color: #f2e7d9;
  height: auto;
}

.remark>div {
  font-size: 16px;
  font-weight: 600;
  color: #563104;
  padding: 10px 20px;
}

@media only screen and (max-width: 800px) {
  .remark>div {
    font-size: 12px;
  }

}

@media print {
  .remark .font_size {
    font-weight: 400;
  };

}

/* Notes section */
.note__section {
  color: #113e21;
  font-weight: 600;
  padding: 30px 20px;
  letter-spacing: 0.7px;
  text-align: center;
  background-color: #e1eae1;
  margin: 10px 0;
}

.note__section .font_size {
  padding: 0;
}

@media only screen and (max-width: 640px) {
  .note__section .font_size {
    font-size: 8px !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1200px) {
  .note__section .font_size {
    font-size: 9px !important;
  }
}

/* Footer */
.report__footer {
  background-color: #113e21;
  color: #fff;
  font-size: 16px;
  padding: 15px;
}

@media only screen and (max-width: 600px) {
  .report__footer {
    font-size: 10px;
  }
}

.report__footer--grid {
  display: grid;
  max-width: 100vw;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}

.report_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

/* water reort styling */
.waterform_section {
  display: none;
}