.dropdown-toggle::after {
    content: none !important;
}   


.notification-item {
    background-color:whitesmoke;
    display: flex;
    align-items: center;
    padding: 10px;
    min-height: 100px;
    transition: background-color 0.3s;
    font-size: 40px;
  }
  
  .notification-item:hover {
    background-color: wheat;
  }
  
  .notification-item .icon {
    margin-right: 10px;
  }
  
  .notification-item .content {
    display: flex;
    flex-direction: column;
  }
  
  .notification-item.alternate {
    background-color: white;
  }