 .login_container
 {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .center_div{
    width:60vw;
    padding: 0.5em;
    margin: 1em auto;
    border-radius: 25px;
    background-color: #113e21;
 }

 .login_image_box {
  display: flex;
  margin: 0 !important;
  justify-content: right;
  align-items: center;
  padding: 0 !important;
}

.login_image_box img {
  object-fit: contain;
  border:15px solid white;
  border-radius: 50px;
  float: right;
}

@media screen and (max-width:1000px)
{
  .center_div
  {
    width: 85vw;
  }
  .input_box
  {
      /* background-image: url("../../../images/LoginImage.png"); */
      background-size: cover;
  }

  .login_image_box
  {
      display: none !important;
  }  
}

@media screen and (min-width:1001px) and (max-width:1200px)
{
  .login_image_box img
  {
      max-width: 350px !important;
      width: 100%;
  }  
}

@media screen and (min-width:1200px)
{
  .login_image_box img
  {
      max-width: 450px !important;
      width: 100%;
  }  
}

@media screen and (max-width:500px)
{
  .login_container .admin-toggler
  {
    width: 100% !important
  }
  
}