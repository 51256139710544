@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
/* .form{
  padding: 0px 20px;
} */
.form__header {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  justify-content: flex-start;
}

.form__header--logo {
  height: 50px;
}

.form__header__name {
  color: #a46414;
  font-size: 25px;
  font-weight: 600;
}

.form__header__name > span {
  color: #113e21;
  font-size: 25px;
  font-weight: 500;
}

.form__body {
  height: auto;
  box-shadow: rgba(50, 50, 93, 0.05) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.1) 0px 3px 7px -3px;
  border-radius: 15px;
  padding-bottom: 1%;
}


form input{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form_errors
{
  margin-top: 5px;
  color: red;
  text-align: left;
}

h3 {
  padding: 20px 0;
  font-size: 35px;
}
h4{
  padding: 15px 0;
}

@media only screen and (max-width: 600px) {
  .form__details {
    padding: 0 20px;
  }
}

.group {
  padding: 10px;
}

.span__text {
  font-size: 13px;
  margin-left: 5px;
  color: gray;
}

.microbialRemarks {
  width: auto;
  margin: 40px 0;
}

.form__button{

    display: flex;
    justify-content: center;
    margin: 0 0 50px 0;
}

.mcol_1
{
  width: 90%;
  align-items: center;
  justify-content: center;

}

.mcol_2
{
  width: 50%;
  align-items: center;

}

