@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
    padding: 0;
    margin: 0;
}


.background {
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
    color: black;
}



.container {
    width: 720px;
    height: 420px;
    background-color: rgba(228, 220, 220, 0.8);
    box-shadow: 0px 0px 2px #f2f2f2;
    border-radius: 15px;
    padding: 20px;
    place-items: center;
}

@media screen and (max-width:600px) {
    .container {
        width: 80vw !important;
        height: 90vw !important;
        position: absolute;
        margin-top: 10px !important;
    }
}

.body_container {
    margin: 20px 30px;
    height: 200px;
}

.input_text {
    font-size: 1.2rem;
}

.input_container {
    display: grid;
    font-size: 1.2rem;
    height: 60px;
}

.input {
    width: 100%;
    height: 40px;
    padding: 5px;
    outline: none;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.5rem;
    border-radius: 5px;
    background-color: none;
}

/* Removes button in input of type number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.submit {
    height: 40px;
    width: 100px;
    font-size: 12px;
    border-radius: 30px 0px 0px 30px !important;
    padding: 20px;
    padding-right: 10px;
    margin: 10px 0;
    height: 15px;
    float: right;
    outline: none;
    cursor: pointer;
}

.back {
    background-color: #a46414;
    height: 40px;
    width: 70px;
    font-size: 12px;
    border-radius: 0px 30px 30px 0px !important;
    padding: 20px;
    margin: 10px 0;
    height: 15px;
    float: left;
    outline: none;
    cursor: pointer;
}

button:hover {
    opacity: 60%;
}

.error {
    color: red;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.success {
    color: green;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.backButton {

    margin: 20px;
    float: right;
}

.logout {
    margin: 20px;
    float: right;
}

a {
    text-decoration: none !important;
}