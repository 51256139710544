.login-container {
  display: flex;
  min-height: 100vh;
}

.login-left {
  width: 50%;
  padding: 20px;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
  background-color: #a5d9b7;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  text-align: left;
  padding: 10px;
}

.left-content {
  padding: 30px;
  padding-left: 10%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -65px;
}

.login-left p {
  margin: 10px 0;
}

.responsive-image {
  border-radius: 15%;
  padding: 20px 0;
  width: 100%;
  height: auto;
  max-width: 40% !important;
  min-width: 360px !important;
}

.login-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  margin-top: -100px;
  padding: 30px;
  width: 92%;
  max-width: 550px;
}

/* City State Dropdown*/
.form__details .css-b62m3t-container {
  border: 1px solid black;
  text-align: left !important;
}

.form__details .css-1fdsijx-ValueContainer {
  padding: 0;
}

.form__details .custom-dropdown input {
  border: none !important;
}

.form__details .css-t3ipsp-control {
  border: none !important;
  box-shadow: none !important;
}

.form__details .css-166bipr-Input input {
  border: none !important;
  box-shadow: none !important;
}

.form__details .css-13cymwt-control {
  border: none;
}


@media (max-width: 768px) {

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center horizontally */
    justify-content: center;
    /* Center vertically */
    min-height: 100vh;
    border-radius: 0 !important;
    margin: 0 auto;
    /* Center horizontally */
    background-color: #a5d9b7;
    /* Background color for the card */
    border-radius: 10px;
    /* Rounded corners for the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Box shadow for the card */
  }

  .left-content {
    align-items: center;
    margin-top: 0px;
    padding: 5px;
  }

  .login-left,
  .login-right {
    width: 90%;
    /* Adjust the width for the card content */
    background-color: white;
  }

  .login-left {
    border-radius: 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .login-form {
    text-align: center;
    padding: 0;
    margin-top: 0;
    padding-bottom: 40px;
  }

  .login-right {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .logo {
    text-align: center;
    margin: 20px 0;
  }

  .responsive-image,
  figure {
    display: none;
  }
}

@media (max-width: 992px) {
  .left-content {
    padding-left: 5%;
  }

  .responsive-image {
    min-width: 300px !important;
  }
}