/* For Popup box */
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  /* Adjust the opacity as needed */
  backdrop-filter: blur(8px);
  /* Adjust the blur intensity as needed */
}

.modal-body .label {
  margin-bottom: 0 !important;
  font-size: 12px;
  font-weight: bold;
}

/* .modal-body .form-control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  padding: 0;
  border-bottom: 2px soild #ced4da !important;
  outline: none !important;
} */

.modal-body select {
  margin-bottom: 1em;
}

.modal-body .form-control:focus {
  outline: none;
  box-shadow: none;
}

.modal-footer button[disabled] {
  opacity: 0.6;
}

.modal-footer button[disabled]:hover {
  opacity: 0.6 !important;
  color: white !important;
}